define("discourse/plugins/discourse-interstitials/discourse/templates/components/modal-content", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#if this.isHtml}}
    {{html-safe @content}}
  {{else}}
    {{cook-text rawText=@content}}
  {{/if}}
  */
  {
    "id": "a+VHmFrI",
    "block": "[[[41,[30,0,[\"isHtml\"]],[[[1,\"  \"],[1,[28,[35,1],[[30,1]],null]],[1,\"\\n\"]],[]],[[[1,\"  \"],[1,[28,[35,2],null,[[\"rawText\"],[[30,1]]]]],[1,\"\\n\"]],[]]]],[\"@content\"],false,[\"if\",\"html-safe\",\"cook-text\"]]",
    "moduleName": "discourse/plugins/discourse-interstitials/discourse/templates/components/modal-content.hbs",
    "isStrictMode": false
  });
});