define("discourse/plugins/discourse-interstitials/discourse/components/debug-options", ["exports", "@ember/component", "@glimmer/component", "@glimmer/tracking", "@ember/object", "@ember/service", "discourse/lib/ajax", "discourse/lib/ajax-error", "@ember/template-factory"], function (_exports, _component, _component2, _tracking, _object, _service, _ajax, _ajaxError, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#if this.shouldShowResetViewsButton}}
    <DButton
      @isLoading={{this.isClearingSeenInterstitial}}
      @translatedLabel="[DEBUG] Reset views"
      @action={{this.clearSeenInterstitial}}
      class="btn-secondary"
    />
  {{/if}}
  */
  {
    "id": "cX0+iqLB",
    "block": "[[[41,[30,0,[\"shouldShowResetViewsButton\"]],[[[1,\"  \"],[8,[39,1],[[24,0,\"btn-secondary\"]],[[\"@isLoading\",\"@translatedLabel\",\"@action\"],[[30,0,[\"isClearingSeenInterstitial\"]],\"[DEBUG] Reset views\",[30,0,[\"clearSeenInterstitial\"]]]],null],[1,\"\\n\"]],[]],null]],[],false,[\"if\",\"d-button\"]]",
    "moduleName": "discourse/plugins/discourse-interstitials/discourse/components/debug-options.hbs",
    "isStrictMode": false
  });
  class DebugOptions extends _component2.default {
    static #_ = (() => dt7948.g(this.prototype, "siteSettings", [_service.service]))();
    #siteSettings = (() => (dt7948.i(this, "siteSettings"), void 0))();
    static #_2 = (() => dt7948.g(this.prototype, "dialog", [_service.service]))();
    #dialog = (() => (dt7948.i(this, "dialog"), void 0))();
    static #_3 = (() => dt7948.g(this.prototype, "isClearingSeenInterstitials", [_tracking.tracked], function () {
      return false;
    }))();
    #isClearingSeenInterstitials = (() => (dt7948.i(this, "isClearingSeenInterstitials"), void 0))();
    get shouldShowResetViewsButton() {
      return this.siteSettings.discourse_interstitial_enable_debug_mode && !this.isClearingSeenInterstitials;
    }
    async clearSeenInterstitial() {
      this.isClearingSeenInterstitials = true;
      try {
        await (0, _ajax.ajax)(`/admin/plugins/discourse-interstitial/interstitials/clear_seen`, {
          type: "POST",
          data: JSON.stringify({
            id: this.args.interstitialId
          }),
          dataType: "json",
          contentType: "application/json"
        });
        this.dialog.alert("cleared successfully, refresh the page to see the changes");
      } catch (e) {
        this.dialog.alert("failed to clear", (0, _ajaxError.extractError)(e));
      } finally {
        this.isClearingSeenInterstitials = false;
      }
    }
    static #_4 = (() => dt7948.n(this.prototype, "clearSeenInterstitial", [_object.action]))();
  }
  _exports.default = DebugOptions;
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, DebugOptions);
});