define("discourse/plugins/discourse-interstitials/discourse/controllers/admin-plugins-discourse-interstitial-new", ["exports", "@ember/controller", "@ember/object", "@ember/service"], function (_exports, _controller, _object, _service) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _controller.default.extend(dt7948.p({
    router: (0, _service.service)(),
    form: null,
    error: null,
    init() {
      this._super(...arguments);
      this._resetForm();
    },
    _resetForm() {
      this.set("form", _object.default.create({
        name: null,
        script: null
      }));
    },
    onCreate(interstitial) {
      this._resetForm();
      this.router.transitionTo("adminPlugins.discourse-interstitial.edit", interstitial.id);
    }
  }, [["method", "onCreate", [_object.action]]]));
});