define("discourse/plugins/discourse-interstitials/discourse/templates/admin-plugins-discourse-interstitial-edit", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    {{interstitials-form
    formType="edit"
    interstitialForm=this.interstitialForm
    onComplete=this.refreshPage
  }}
  */
  {
    "id": "b7HsBK5I",
    "block": "[[[1,[28,[35,0],null,[[\"formType\",\"interstitialForm\",\"onComplete\"],[\"edit\",[30,0,[\"interstitialForm\"]],[30,0,[\"refreshPage\"]]]]]]],[],false,[\"interstitials-form\"]]",
    "moduleName": "discourse/plugins/discourse-interstitials/discourse/templates/admin-plugins-discourse-interstitial-edit.hbs",
    "isStrictMode": false
  });
});