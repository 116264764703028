define("discourse/plugins/discourse-interstitials/discourse/templates/components/interstitial-field", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#if this.displayField}}
    {{#if this.displayPlaceholders}}
      <div class="placeholders-list">
        <p class="placeholder-description">
          <span>{{i18n "discourse_interstitial.placeholders.description"}}</span>
          {{#each this.interstitial.placeholders as |placeholder|}}
            <a
              href
              class="placeholder-item"
              {{on "click" (action "copyPlaceholder" placeholder)}}
            >
              {{placeholder}}
            </a>
          {{/each}}
        </p>
      </div>
    {{/if}}
  
    {{component
      (concat "fields/di-" this.field.component "-field")
      field=this.field
      onChangeField=this.onChangeField
      label=(i18n
        (concat
          "discourse_interstitial" this.target "fields." this.field.name ".label"
        )
      )
      description=this.description
    }}
  {{/if}}
  */
  {
    "id": "B11TiMdS",
    "block": "[[[41,[30,0,[\"displayField\"]],[[[41,[30,0,[\"displayPlaceholders\"]],[[[1,\"    \"],[10,0],[14,0,\"placeholders-list\"],[12],[1,\"\\n      \"],[10,2],[14,0,\"placeholder-description\"],[12],[1,\"\\n        \"],[10,1],[12],[1,[28,[35,4],[\"discourse_interstitial.placeholders.description\"],null]],[13],[1,\"\\n\"],[42,[28,[37,6],[[28,[37,6],[[30,0,[\"interstitial\",\"placeholders\"]]],null]],null],null,[[[1,\"          \"],[11,3],[24,6,\"\"],[24,0,\"placeholder-item\"],[4,[38,8],[\"click\",[28,[37,9],[[30,0],\"copyPlaceholder\",[30,1]],null]],null],[12],[1,\"\\n            \"],[1,[30,1]],[1,\"\\n          \"],[13],[1,\"\\n\"]],[1]],null],[1,\"      \"],[13],[1,\"\\n    \"],[13],[1,\"\\n\"]],[]],null],[1,\"\\n  \"],[46,[28,[37,11],[\"fields/di-\",[30,0,[\"field\",\"component\"]],\"-field\"],null],null,[[\"field\",\"onChangeField\",\"label\",\"description\"],[[30,0,[\"field\"]],[30,0,[\"onChangeField\"]],[28,[37,4],[[28,[37,11],[\"discourse_interstitial\",[30,0,[\"target\"]],\"fields.\",[30,0,[\"field\",\"name\"]],\".label\"],null]],null],[30,0,[\"description\"]]]],null],[1,\"\\n\"]],[]],null]],[\"placeholder\"],false,[\"if\",\"div\",\"p\",\"span\",\"i18n\",\"each\",\"-track-array\",\"a\",\"on\",\"action\",\"component\",\"concat\"]]",
    "moduleName": "discourse/plugins/discourse-interstitials/discourse/templates/components/interstitial-field.hbs",
    "isStrictMode": false
  });
});