define("discourse/plugins/discourse-interstitials/discourse/controllers/admin-plugins-discourse-interstitial", ["exports", "@ember/controller", "@ember/object", "@ember/object/computed", "@ember/service"], function (_exports, _controller, _object, _computed, _service) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _controller.default.extend(dt7948.p({
    newInterstitial() {
      this.router.transitionTo("adminPlugins.discourse-interstitial.new");
    },
    router: (0, _service.service)(),
    showNewInterstitial: (0, _computed.equal)("router.currentRouteName", "adminPlugins.discourse-interstitial.index")
  }, [["method", "newInterstitial", [_object.action]]]));
});