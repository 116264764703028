define("discourse/plugins/discourse-interstitials/discourse/templates/admin-plugins-discourse-interstitial-new", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    {{interstitials-form
    formType="new"
    interstitialForm=this.interstitialForm
    onComplete=this.onCreate
  }}
  */
  {
    "id": "Z3usdJV8",
    "block": "[[[1,[28,[35,0],null,[[\"formType\",\"interstitialForm\",\"onComplete\"],[\"new\",[30,0,[\"interstitialForm\"]],[30,0,[\"onCreate\"]]]]]]],[],false,[\"interstitials-form\"]]",
    "moduleName": "discourse/plugins/discourse-interstitials/discourse/templates/admin-plugins-discourse-interstitial-new.hbs",
    "isStrictMode": false
  });
});