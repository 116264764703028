define("discourse/plugins/discourse-interstitials/discourse/templates/components/cta-button", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    {{d-button
    class=this.ctaButtonClass
    translatedLabel=this.button.title
    action=this.action
    disabled=this.saving
  }}
  */
  {
    "id": "gYpL9PEU",
    "block": "[[[1,[28,[35,0],null,[[\"class\",\"translatedLabel\",\"action\",\"disabled\"],[[30,0,[\"ctaButtonClass\"]],[30,0,[\"button\",\"title\"]],[30,0,[\"action\"]],[30,0,[\"saving\"]]]]]]],[],false,[\"d-button\"]]",
    "moduleName": "discourse/plugins/discourse-interstitials/discourse/templates/components/cta-button.hbs",
    "isStrictMode": false
  });
});